<template>
  <div
    id="tab-economy"
    :class="['tab-pane', { active: active }]"
    aria-labelledby="tab-economy-tab"
    role="tabpanel"
  >
    <div
      v-if="!loading"
      class="card card--table"
    >
      <div class="card-content collapse show">
        <div class="card-body">
          <h4>Memòria econòmica</h4>
          <a
            v-if="user.economy_document"
            :href="user.economy_document.url"
            target="_blank"
            title="view document"
            class="btn bg-light-secondary btn-sm"
          ><i data-feather="eye" /> Veure el document</a>
          <template v-else>
            <div class="mb-1">
              <label
                class="form-label"
                for=""
              >Si us plau, descarregui la plantilla de la memòria econòmica, ompli'l i pugi'l aquí:</label>
              <input
                :ref="'file-new'"
                type="file"
                class="form-control"
                @change="uploadFile"
              >
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      id="loading-bg"
    >
      <div
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    active: {
      type: Boolean,
      required: String,
    },
  },
  data() {
    return {
      saving: false,
      documentsAmount: [],
      docs: [],
      pendingJustified: 0,
      totalJustified: 0,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'academyAwarded/item',
      loggedUser: 'auth/admin',
    }),
  },
  watch: {
    'user.id': function () {
      this.syncVars()
    },
  },
  mounted() {
    this.syncVars()

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
  methods: {
    syncVars() {
      /* this.documentsAmount = this.user.documents.map(e => e.amount)
      this.docs = [...this.user.documents]
      this.totalJustified = this.user.totalJustified
      this.pendingJustified = this.user.pendingJustified */
    },
    async uploadFile() {
      if (this.$refs['file-new']) {
        const file = this.$refs['file-new'].files[0]

        if (file) {
          const fileEconomyMemory = {
            name: file.name,
            original_name: file.name,
            description: 'Economy Memory Signed',
            weight: (file.size / 1024 / 1024).toFixed(2),
            extension: file.type,
            created_at: 'Pending to save...',
            file,
            file_type: file.type,
          }

          try {
            await this.$store.dispatch('academyAwarded/economyFile', { userId: this.user.id, file: fileEconomyMemory })
          } catch (e) {
            console.log(e)
          }

          Vue.prototype.$saveFiles([fileEconomyMemory])

          setTimeout(() => {
            feather.replace({
              width: 14,
              height: 14,
            })
          }, 200)
        }
      } else {
        console.log('UPLOAD FILE ERROR')
      }
    },
    changeAmount(index) {
      this.docs[index].amountFormat = this.formatFloat(this.docs[index].amountFormat)
      const amounts = this.docs.map(e => this.parseFloatToNumber(e.amountFormat))
      this.totalJustified = this.formatFloat(amounts.reduce((sum, amount) => sum + amount))
      this.pendingJustified = this.formatFloat(this.user.import - this.parseFloatToNumber(this.totalJustified))
    },
    async save() {
      this.loading = true
      this.saving = true
      try {
        await this.$store.dispatch('academyAwarded/saveEconomyJustified', { userId: this.user.id, data: { docs: this.docs } })
        Vue.swal('Els anys han estat justificats correctament!', '', 'success')
      } catch (e) {
        console.log(e)
      }
      this.saving = false
      this.loading = false
    },
    formatFloat(float) {
      if (float) {
        const value = float.toString().replace(/[^\d.]/g, '') // Remove non-numeric characters
        const parts = value.split(',') // Split into integer and decimal parts

        // Add commas as thousands separators in the integer part
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        // Reconstruct the formatted value
        const formattedValue = parts.join('.')

        // Update the input value
        return float < 0 ? `- ${formattedValue}` : formattedValue
      }
      return float
    },
    parseFloatToNumber(num) {
      if (num) {
        return Number(num.split(',').join('').split('.').join(''))
      }
      return 0
    },
  },
}
</script>
